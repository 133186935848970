<template>
  <svg width="41" height="15" viewBox="0 0 41 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20.3239 0.548828C13.4467 0.548828 6.32549 1.85668 2.81497 5.42979C1.40074 6.85654 0.593506 8.63997 0.593506 10.7426C0.593506 12.5385 1.16295 14.6661 3.07779 14.81C3.51582 14.9227 3.97889 14.8788 4.54208 14.7912L9.38551 13.984C11.0813 13.7087 11.8948 13.0704 12.3516 11.4246L13.1588 8.50227C13.309 7.9391 13.528 7.69504 14.1538 7.4698C15.543 6.99418 17.6143 6.71885 20.3239 6.71885C23.0334 6.7251 25.1047 6.99418 26.4876 7.4698C27.1134 7.69504 27.3387 7.9391 27.4889 8.50227L28.2961 11.4246C28.7529 13.0704 29.5664 13.7087 31.2622 13.984L36.0994 14.7912C36.6751 14.8788 37.1256 14.9227 37.5637 14.81C39.4785 14.6661 40.0542 12.5385 40.0542 10.7426C40.0542 8.63997 39.247 6.85654 37.8327 5.42979C34.316 1.85668 27.2073 0.548828 20.3239 0.548828Z" fill="white"/>
  </svg>
</template>

<script>
export default {
  name: 'CallClosedIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#b5bec7'
    }
  }

}
</script>

<style>

</style>
