import { mapState } from 'vuex'
import Colors from '@/utils/Colors'
import { EventBus } from '@/eventBus'
import Format from '@/factory/PrettySize'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import ImporterUseCases from '@/modules/imports/application/ImporterUseCases'
import ImporterController from '../../../controllers/Importer.controller'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import KBIconButton from '@/shared/infrastructure/ui/buttons/KBIconButton.vue'
import EmitToast from '@/utils/EmitToast'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
export default {
  components: {
    KeybeIcon,
    KBIconButton
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token']),
    processFileNameUploaded (): string {
      if (this.file) {
        const filename: string = this.file.name.split('.')[0] || ''
        if (filename.length > 20) {
          return filename.substring(0, 20) + '...' + this.file.name.split('.')[1]
        }
        return this.file.name
      }
      return ''
    }
  },
  data () {
    return {
      loading: false,
      tooBig: false,
      file: null,
      headers: [],
      persons: [],
      mappedHeaders: [],
      fileUploadData: {},
      importerController: new ImporterController(),
      Colors,
      KeybeIconType
    }
  },
  methods: {
    formatSize (bytes) {
      return Format.prettySize(bytes)
    },
    onFileDropped (event) {
    },
    onFilePicked (event) {
      if (event) {
        this.loading = true
        this.tooBig = false
        const file = event?.target?.files[0]
        const filename = file?.name
        const fileParts = filename?.split('.')
        const fileExt = fileParts[fileParts.length - 1]
        if (filename.lastIndexOf('.') <= 0 || (fileExt !== 'xlsx' && fileExt !== 'xlsm' && fileExt !== 'xls')) {
          return EventBus.$emit('toast', 'error', this.$t('pleaseAddAValidFile'))
        }
        if (file) this.file = file
        if (file.size > 50000000) {
          EventBus.$emit('toast', 'error', this.$t('maxFileSize50mb'))
          this.tooBig = true
          return
        }
        this.processFile()
      }
    },
    openFilePicker () {
      this.$refs.fileInput.click()
    },
    removeFile () {
      this.file = null
      // @ts-ignore
      document.getElementById('file_uploader').value = ''
      this.fileUploadData = {}
      this.mappedHeaders = []
      this.headers = []
      this.$emit('fileUploadData', this.fileUploadData)
      this.$emit('headers', this.mappedHeaders)
    },
    async processFile () {
      try {
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid || '',
          file: this.file,
          token: this.token || ''
        }
        const response = await ImporterUseCases.headerDetect(this.importerController, data)
        if (response?.status) {
          this.headers = response?.data?.columnNames
          this.persons = response?.data?.data
          this.fileUploadData = response?.data?.fileUploadData
          this.setHeaders()
        } else {
          if (Array.isArray(response.message)) {
            const errors = response.message.map((error) => {
              return this.$t(error)
            })
            EmitToast.emitErrors(errors.toString())
          }
        }
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    mapHeaderAutomatically (header) {
      const headerObject = { column: header, field: '', fieldParent: '', category: '' }
      if (header && typeof header === 'string') {
        if (header.toLowerCase() === 'first name' ||
            header.toLowerCase() === 'nombre' ||
            header.toLowerCase() === CDPFieldsEnums.NAME) {
          headerObject.field = CDPFieldsEnums.NAME
        } else if (header.toLowerCase() === 'last name' ||
                  header.toLowerCase() === 'apellido' ||
                  header.toLowerCase() === CDPFieldsEnums.SURNAME) {
          headerObject.field = CDPFieldsEnums.SURNAME
        } else if (header.toLowerCase() === 'email' ||
                  header.toLowerCase() === 'correo' ||
                  header.toLowerCase() === 'correo electrónico' ||
                  header.toLowerCase() === 'correo electronico') {
          headerObject.field = 'email'
        } else if (header.toLowerCase() === CDPFieldsEnums.PHONE ||
                  header.toLowerCase() === 'telefono' ||
                  header.toLowerCase() === 'teléfono' ||
                  header.toLowerCase() === 'numero de telefono') {
          headerObject.field = CDPFieldsEnums.PHONE
        } else if (header.toLowerCase() === 'status' ||
                  header.toLowerCase() === 'estado' ||
                  header.toLowerCase() === 'journey') {
          headerObject.field = 'status'
        }
        return headerObject
      }
      return headerObject
    },
    setHeaders () {
      this.headers.forEach(head => {
        this.mappedHeaders.push(this.mapHeaderAutomatically(head))
      })
      this.$emit('fileUploadData', this.fileUploadData)
      this.$emit('headers', this.mappedHeaders)
      this.$emit('persons', this.persons)
    }
  }
}
