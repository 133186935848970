import { dataToChangePassword, dataToLogIn, dataToLogInByToken, ILogInController, logInResponse } from '../domain/LoginController'

export default class LogInUseCases {
  static abort (controller: ILogInController) : void {
    controller.abort()
  }

  static async login (controller: ILogInController, data: dataToLogIn) : Promise<logInResponse> {
    return await controller.login(data)
  }

  static async loginByToken (controller: ILogInController, data: dataToLogInByToken) : Promise<boolean> {
    return await controller.loginByToken(data)
  }

  static async changePassword (controller: ILogInController, data: dataToChangePassword) : Promise<boolean> {
    return await controller.changePassword(data)
  }
}
