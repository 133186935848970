<template>
  <svg width="26" height="36" viewBox="0 0 26 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 17.4325C0 24.2982 4.68126 28.9061 11.2813 29.5785V32.4716H5.37498C4.38125 32.4716 3.5375 33.2612 3.5375 34.2358C3.5375 35.1919 4.38125 36 5.37498 36H20.6375C21.625 36 22.4688 35.1919 22.4688 34.2358C22.4688 33.2612 21.625 32.4716 20.6375 32.4716H14.725V29.5785C21.3188 28.9061 26 24.2982 26 17.4325V14.3975C26 13.4291 25.1938 12.6703 24.1938 12.6703C23.1938 12.6703 22.3688 13.4291 22.3688 14.3975V17.3214C22.3688 22.7252 18.4875 26.2721 13 26.2721C7.51253 26.2721 3.6375 22.7252 3.6375 17.3214V14.3975C3.6375 13.4291 2.80625 12.6703 1.8125 12.6703C0.812499 12.6703 0 13.4291 0 14.3975V17.4325ZM13 23.0398C16.3125 23.0398 18.95 20.5723 18.95 16.9205V6.12544C18.95 2.48595 16.3125 0 13 0C9.68751 0 7.03125 2.47978 7.03125 6.11928V16.9205C7.03125 20.5723 9.68751 23.0398 13 23.0398Z" fill="black" fill-opacity="0.85"/>
  </svg>
</template>

<script>
export default {
  name: 'CallMicrophoneIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#b5bec7'
    }
  }

}
</script>

<style>

</style>
