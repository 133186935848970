<template>
  <svg width="22" height="30" viewBox="0 0 22 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.44918 29.3571H6.33517C7.96214 29.3571 8.78193 28.5373 8.78193 26.9103V3.33151C8.78193 1.69826 7.96214 0.884766 6.33517 0.884766H2.44918C0.822192 0.884766 0.00244141 1.70452 0.00244141 3.33151V26.9103C0.00244141 28.5373 0.797161 29.3571 2.44918 29.3571ZM15.1272 29.3571H19.0132C20.6402 29.3571 21.4599 28.5373 21.4599 26.9103V3.33151C21.4599 1.69826 20.6402 0.884766 19.0132 0.884766H15.1272C13.5002 0.884766 12.6804 1.70452 12.6804 3.33151V26.9103C12.6804 28.5373 13.4814 29.3571 15.1272 29.3571Z" fill="black" fill-opacity="0.85"/>
  </svg>
</template>

<script>
export default {
  name: 'CallPauseIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#b5bec7'
    }
  }

}
</script>

<style>

</style>
