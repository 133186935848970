/* eslint-disable camelcase */
import { PartnerTypEnum } from '@/components/models/App'
import { dataToGetPartnerUtmInformation, IPartnersController, PartnerUtmInformationGetter } from '../../domain/PartnersController'
import store from '@/store'
import { i18n } from '@/plugins/i18n/i18n'
import PartnersRepository from '../repository/Partners.repository'

export default class PartnersController implements IPartnersController {
  repository: PartnersRepository

  constructor () {
    this.repository = new PartnersRepository()
  }

  async getCommissions (data: any): Promise<any> {
    return ''
  }

  async getPartnerUrl (type: string): Promise<string> {
    const brandEmail = store.getters['AppStore/getSelectedApp'].email
    const token = store.getters['UserStore/getToken']
    const partnerType = store.getters['AppStore/getSelectedApp'].partnerType || ''
    let baseUrl = ''
    const utm_source = partnerType || ''
    let utm_medium = ''
    const utm_campaign = partnerType || ''
    const utm_term = store.getters['UserStore/getUser'].uuid || ''
    const utm_content = store.getters['AppStore/getSelectedApp'].uuid || ''
    let utm_partner
    const utmPartnerResponse = await this.getPartnerUtmInformation({ email: brandEmail, token: token })
    if (utmPartnerResponse.status) {
      utm_partner = utmPartnerResponse?.data?._id || ''
    } else {
      utm_partner = utmPartnerResponse?.message?.replaceAll(' ', '_') || ''
    }

    if (type === 'biky') {
      baseUrl = `${process.env.VUE_APP_BIKY_URL}/signup`
    } else {
      baseUrl = `${process.env.VUE_APP_BASE_KEYBE_URL}/signup`
    }

    if (partnerType === PartnerTypEnum.PARTNER_PRO) {
      utm_medium = store.getters['AppStore/getSelectedApp']?.name?.replace(/ /g, '_') || ''
    } else if (partnerType === PartnerTypEnum.PARTNER_REFERRAL) {
      const name = store.getters['UserStore/getUser']?.name?.replace(/ /g, '_') || ''
      const lastname = store.getters['UserStore/getUser']?.lastName?.replace(/ /g, '_') || ''
      utm_medium = `${name}_${lastname}` || ''
    } else if (partnerType === PartnerTypEnum.REFERRAL_OF_PARTNER) {
      utm_medium = store.getters['AppStore/getSelectedApp']?.partnerResponsible || ''
      if (!utm_medium) return i18n.t('noPartnerResponsible').toString() || ''
    }
    console.log('==================UTMS==================')
    console.log('baseUrl', baseUrl)
    console.log('utm_source', utm_source)
    console.log('utm_medium', utm_medium)
    console.log('utm_campaign', utm_campaign)
    console.log('utm_term', utm_term)
    console.log('utm_content', utm_content)
    console.log('utm_partner', utm_partner)
    console.log('=======================================')
    return `${baseUrl}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_term=${utm_term}&utm_content=${utm_content}&utm_partner=${utm_partner}`
  }

  async getPartnerUtmInformation (data: dataToGetPartnerUtmInformation): Promise<PartnerUtmInformationGetter> {
    const response = await this.repository.getPartnerUtmInformation(data)
    if (response?.status) {
      return { status: response.status, data: response.data }
    } else {
      return { status: response.status, message: response.message }
    }
  }
}
