<template>
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.671631 14.0945C0.671631 15.2834 1.64783 16.2471 2.83052 16.2471H12.2045V25.621C12.2045 26.8038 13.1744 27.78 14.3571 27.78C15.5523 27.78 16.5222 26.8038 16.5222 25.621V16.2471H25.8962C27.0789 16.2471 28.0551 15.2834 28.0551 14.0945C28.0551 12.8992 27.0789 11.9356 25.8962 11.9356H16.5222V2.56163C16.5222 1.37268 15.5523 0.396484 14.3571 0.396484C13.1744 0.396484 12.2045 1.37268 12.2045 2.56163V11.9356H2.83052C1.64783 11.9356 0.671631 12.8992 0.671631 14.0945Z" fill="black" fill-opacity="0.85"/>
  </svg>
</template>

<script>
export default {
  name: 'CallPlusIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#b5bec7'
    }
  }

}
</script>

<style>

</style>
