import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import StatusUseCases from '@/modules/Statuses/application/StatusUseCases'
import { Status } from '@/modules/Statuses/domain/Status'
import StatusController from '@/modules/Statuses/infrastructure/controllers/Status.controller'
import Colors from '@/utils/Colors'
import EmitToast from '@/utils/EmitToast'
import operations from '@/utils/operations'
import { debounce } from 'lodash'
import { mapState } from 'vuex'

export default {
  props: {
    placeholder: {
      type: String
    },
    disabled: {
      type: Boolean
    },
    value: {
      type: String
    }
  },
  computed: {
    ...mapState('UserStore', ['token']),
    ...mapState('AppStore', ['selectedApp']),
    selectedItemLabel (): string {
      if (this.loading) return this.placeholder
      return this.selectedItem ? this.selectedItem[CDPFieldsEnums.NAME] : this.placeholder
    },
    labelStyle (): string {
      return this.selectedItem
        ? `color: ${this.$vuetify.theme.dark ? Colors.white : Colors.black}`
        : `color: ${Colors.simpleGrey}`
    },
    itemStyle (): string {
      return this.$vuetify.theme.dark
        ? `background-color: ${Colors.morningCoffee}; border-color: ${Colors.grey}`
        : `background-color: ${Colors.white}; border-color: ${Colors.light4}`
    }
  },
  mounted () {
    this.getStatuses()
  },
  data () {
    return {
      selectedItem: '',
      loading: false,
      items: [],
      dependenciesItems: [],
      open: false,
      statusController: new StatusController()
    }
  },
  methods: {
    openMenu (): void {
      if (!this.disabled) {
        this.open = !this.open
      }
    },
    statusBallStyle (item: Status): string {
      return `background-color: ${item.color};`
    },
    itemLabel (item: Status): string {
      return item[CDPFieldsEnums.NAME]
    },
    async getStatuses () {
      try {
        this.loading = true
        const data = {
          app: this.selectedApp?.uuid,
          token: this.token,
          pagination: {
            offset: 0,
            limit: 200
          }
        }
        const response = await StatusUseCases.getStatuses(this.statusController, data)
        if (Array.isArray(response)) {
          this.items = response
          this.items.forEach((item: Status) => {
            item.name = operations.checkTranslation(item.name)
          })
          this.setValue()
        } else EmitToast.emitErrors(response)
        this.loading = false
      } catch (e) {
        console.error(e)
        this.loading = false
      }
    },
    selectItem: debounce(function (item: any) {
      this.selectedItem = item
      const value: string = this.selectedItem?._id
      this.$emit('input', value)
    }, 600),
    setValue () {
      const item: Status = this.items.find((item: Status) => item._id === this.value)
      this.selectedItem = item
      this.dependenciesItems = this.items.filter((item: Status) => {
        if (!this.selectedItem) return item.dependencies.length === 0
        else return item.dependencies.includes(this.selectedItem?._id) || item.dependencies.length === 0
      })
    }
  },
  watch: {
    value () {
      this.setValue()
    }
  }
}
