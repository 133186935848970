import KBSearch from '@/shared/infrastructure/ui/inputs/text/KBSearch.vue'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { AdminTypes } from '@/components/configuration/administrators/enums/AdminTypes'
import KeybeAvatar from '@/shared/infrastructure/ui/KeybeAvatar.vue'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import { TeamMember } from '../../../domain/models/TeamMember'
import { debounce } from 'lodash'
import Loader from '@/shared/infrastructure/ui/Loader.vue'
import Colors from '@/utils/Colors'
import { ConfigurationTabsEnum } from '@/modules/configuration/application/enums/ConfigurationTabsEnum'
import { EventBus } from '@/eventBus'
import KBButtonLoader from '@/shared/infrastructure/ui/progress/KBButtonLoader/KBButtonLoader.vue'
import { dataToChangeReadonly, dataToGetTeamMembers, dataToUpdateTeamMember, teamMemberCreate, teamMemberGetter } from '../../../domain/TeamController'
import TeamUseCases from '../../../application/TeamUseCases'
import TeamController from '../../controllers/Team.controller'
import { mapState, mapGetters } from 'vuex'
import KBTabs from '@/shared/infrastructure/ui/menus/KBTabs.vue'
import KBSwitch from '@/shared/infrastructure/ui/inputs/select/KBSwitch.vue'
import EmitToast from '@/utils/EmitToast'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import { ActivityLogModuleTypeEnum, ActivityActionTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import BikyStoreController from '@/modules/bky/infrastructure/persistence/BikyStore.controller'
export default {
  components: {
    KBSearch,
    KeybeButton,
    KeybeAvatar,
    KeybeIcon,
    Loader,
    KBButtonLoader,
    KBTabs,
    KBSwitch
  },
  computed: {
    ...mapState('AppStore', ['selectedApp']),
    ...mapState('UserStore', ['token', 'user', 'isUserReadonly']),
    ...mapGetters('UserStore', ['isSuperAdmin']),
    teamSize (): number {
      return this.teamMembers.length
    },
    midSize (): boolean {
      return window.innerWidth > 1400
    }
  },
  mounted () {
    this.getTeam()
    if (this.onboardingStep === 7) {
      this.nextStep()
    }
  },
  data () {
    return {
      teamMembers: [],
      search: '',
      onboardingStep: null,
      loading: false,
      teamController: new TeamController(),
      activityLogsController: new ActivityLogsController(),
      bikyConfig: {},
      tabs: [
        this.$t('activeAccounts'),
        this.$t('inactiveAccounts')
      ],
      selectedTabIndex: 0,
      KeybeIconType,
      Colors
    }
  },
  methods: {
    isMemberSuperadmin (member: TeamMember): boolean {
      return member.ua_role === AdminTypes.SUPER_ADMIN || member.isSuperAdmin === 1
    },
    async changeReandonly (member: TeamMember): Promise<void> {
      const data: dataToChangeReadonly = {
        token: this.token,
        appUUID: this.selectedApp?.uuid,
        id: member.uuid,
        member: member
      }
      const response = await TeamUseCases.changeReadonly(this.teamController, data)
      if (response.status) {
        await this.writeActivityLogReadonly(member)
        this.teamMembers[this.teamMembers.findIndex((m: TeamMember) => m.uuid === member.uuid)].ua_readonly = member.ua_readonly === 0 ? 1 : 0
      } else {
        EmitToast.emitErrors(response.message)
      }
    },
    goToUpdate (member: TeamMember): void {
      if (this.isBiky(member) || this.isUserReadonly) return
      this.$emit('updateMember', member)
    },
    onTabChange (index: number): void {
      this.selectedTabIndex = index
      this.getTeam()
    },
    async changeStatus (member: TeamMember): Promise<void> {
      if (!this.isUserReadonly) {
        const data: dataToUpdateTeamMember = {
          token: this.token,
          uuid: member.uuid,
          updateInput: {
            appUUID: this.selectedApp?.uuid,
            uuid: member.uuid,
            inactive: member.ua_inactive === 0 ? 1 : 0
          }
        }
        const response: teamMemberCreate = await TeamUseCases.updateTeamMember(this.teamController, data)
        if (response.status) {
          await this.writeActivityLog(member)
          this.getTeam()
        } else {
          EmitToast.emitErrors(response.message)
        }
      }
    },
    async writeActivityLog (member: TeamMember): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.TEAM,
          action: ActivityActionTypeEnum.TEAM_MEMBER_UPDATE,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          object: `{ "id": "${member.uuid}", "name": "${member.name} ${member.lastName}" }`,
          payload: {
            field: CDPFieldsEnums.INACTIVE,
            newValue: member.ua_inactive === 0 ? 'inactive' : 'active',
            oldValue: member.ua_inactive === 0 ? 'active' : 'inactive'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    async writeActivityLogReadonly (member: TeamMember): Promise<void> {
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.TEAM,
          action: ActivityActionTypeEnum.TEAM_MEMBER_READONLY,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          object: `{ "id": "${member.uuid}", "name": "${member.name} ${member.lastName}" }`,
          payload: {
            field: CDPFieldsEnums.INACTIVE,
            newValue: member.ua_readonly === 0 ? 'inactive' : 'active',
            oldValue: member.ua_readonly === 0 ? 'active' : 'inactive'
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    nextStep (): void {
      // eslint-disable-next-line no-undef
      const newStep: number = structuredClone(this.onboardingStep) + 1
      this.setOnboardingStep(newStep)
      EventBus.$emit('setConfigurationTab', ConfigurationTabsEnum.TEAM)
      EventBus.$emit('openInviteTeam--team-config')
    },
    image (member: TeamMember): string {
      if (this.isBiky(member)) {
        this.bikyConfig = BikyStoreController.getConfig()
        return this.bikyConfig?.image || `${process.env.VUE_APP_STORAGE_URL}/files/ai/montes_profile_5.png`
      }
      return member?.photo || ''
    },
    icon (member: TeamMember): string {
      switch (member.ua_role) {
        case AdminTypes.SUPER_ADMIN:
          return KeybeIconType.SUPERADMIN_USER
        case AdminTypes.ADMIN:
          return KeybeIconType.ADMIN_USER
        case AdminTypes.COORDINATOR:
          return KeybeIconType.COORDINATOR_USER
        case AdminTypes.AGENT:
          return KeybeIconType.AGENT_USER
      }
    },
    phoneNumber (member: TeamMember): string {
      return member?.phone && member?.phone?.length > 4 ? '+' + member?.countryCode + member?.phone : ''
    },
    email (member: TeamMember): string {
      return member?.email
    },
    userType (member: TeamMember): string {
      return member?.ua_role
    },
    name (member: TeamMember): string {
      if (this.isBiky(member)) {
        this.bikyConfig = BikyStoreController.getConfig()
        return this.bikyConfig?.name || 'Biky Montes'
      }
      return member?.name + ' ' + member.lastName
    },
    setSearch: debounce(function () {
      this.getTeam()
    }, 300),
    async getTeam (): Promise<void> {
      try {
        this.loading = true
        const data: dataToGetTeamMembers = {
          page: 0,
          limit: 500,
          search: this.search,
          appId: this.selectedApp?.uuid,
          token: this.token,
          inactive: this.selectedTabIndex
        }
        const response: teamMemberGetter = await TeamUseCases.getTeamMembers(this.teamController, data)
        const teamMembers: TeamMember[] = response?.team || []
        this.teamMembers = teamMembers
        this.loading = false
      } catch (e) {
        this.loading = false
        console.error(e)
      }
    },
    isBiky (member: TeamMember): boolean {
      return TeamUseCases.isUserBiky(this.teamController, member)
    }
  }
}
