import KBSwitch from '@/shared/infrastructure/ui/switch/KBSwitch.vue'
import { KeybeIconType } from '@/shared/infrastructure/enums/icons/KeybeIconType'
import { mapState } from 'vuex'
import KeybeIcon from '@/shared/infrastructure/ui/icons/KeybeIcon.vue'
import Colors from '@/shared/domain/colors/Color'
import WhatsappPreview from '../WhatsappPreview/WhatsappPreview.vue'
import InstagramPreview from '../InstagramPreview/InstagramPreview.vue'
import FacebookPreview from '../FacebookPreview/FacebookPreview.vue'
import WebPreview from '../WebPreview/WebPreview.vue'
import TemplateTextEditor from '../TemplateTextEditor/TemplateTextEditor.vue'
import ModalWarning from '@/modules/Spaces/infrastructure/ui/SpacesRules/ModalWarning/ModalWarning.vue'
import { messageOnTransfer } from '@/modules/Spaces/domain/SpacesController'
import { debounce } from 'lodash'
import { ActivityActionTypeEnum, ActivityLogModuleTypeEnum, ActivitySubmoduleTypeEnum, dataToWriteActivityLog } from '@/modules/Config/ActivityLogs/domain/ActivityLogsController'
import { CDPFieldsEnums } from '@/modules/CDP/shared/contacts/domain/enums/CDPFieldsEnums'
import ActivityLogsUseCases from '@/modules/Config/ActivityLogs/application/ActivityLogsUseCases'
import ActivityLogsController from '@/modules/Config/ActivityLogs/infrastructure/controllers/ActivityLogs.controller'
import KeybeButton from '@/modules/DesignSystem/infrastructure/components/KeybeButton/KeybeButton.vue'
import { EventBus } from '@/eventBus'

export default {
  name: 'WelcomeMessageConfig',
  components: {
    KBSwitch,
    KeybeIcon,
    WhatsappPreview,
    InstagramPreview,
    FacebookPreview,
    WebPreview,
    TemplateTextEditor,
    ModalWarning,
    KeybeButton
  },
  props: {
    inboxList: {
      type: Array,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      KeybeIconType,
      Colors,
      greetingMessage: '',
      adminType: false,
      isWhatsappPreview: true,
      isInstagramPreview: false,
      isFacebookPreview: false,
      isWebPreview: false,
      isWelcomeMessageEnabled: false,
      showWarning: false,
      messageWarning: '',
      messageActive: false,
      localInbox: [],
      activityLogsController: new ActivityLogsController()
    }
  },
  async created () {
    await this.isAdmin()
    await this.setInbox(this.inboxList)
    this.getConfig()
    EventBus.$on('inboxUpdated--spaces', this.setInbox.bind(this))
  },
  computed: {
    ...mapState('UserStore', ['user']),
    ...mapState('AppStore', ['advisers', 'selectedApp'])
  },
  methods: {
    setInbox (inbox: any): void {
      // eslint-disable-next-line no-undef
      this.localInbox = structuredClone(inbox)
    },
    getConfig () {
      if (this.inboxList[this.index].messageOnTransfer === undefined || this.inboxList[this.index].messageOnTransfer.length === 0) {
        this.showWarning = true
        this.inboxList[this.index].messageOnTransfer = []
        this.localInbox[this.index].messageOnTransfer = this.inboxList[this.index]?.messageOnTransfer
        this.messageWarning = this.$t('messageWarning', { propsConfig: this.$t('welcomeMessageTitle') })
      } else {
        this.changePreview('keybe_wp')
      }
    },
    changePreview (preview: string) {
      this.isWhatsappPreview = false
      this.isInstagramPreview = false
      this.isFacebookPreview = false
      this.isWebPreview = false

      switch (preview) {
        case 'keybe_wp':
          this.isWhatsappPreview = true
          this.greetingMessage = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'keybe_wp')?.message || ''
          this.messageActive = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'keybe_wp')?.active
          break
        case 'instagram':
          this.isInstagramPreview = true
          this.greetingMessage = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'instagram')?.message || ''
          this.messageActive = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'instagram')?.active
          break
        case 'facebookMessenger':
          this.isFacebookPreview = true
          this.greetingMessage = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'facebookMessenger')?.message || ''
          this.messageActive = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'facebookMessenger')?.active
          break
        case 'web':
          this.isWebPreview = true
          this.greetingMessage = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'web')?.message || ''
          this.messageActive = this.localInbox[this.index]?.messageOnTransfer?.find((message: messageOnTransfer) => message.channel === 'web')?.active
          break
      }
    },
    isAdmin () {
      const userUuid = this.user.uuid
      const isUserAdviser = this.advisers.some(adviser => adviser.uuid === userUuid && adviser.role !== 'agent')
      if (isUserAdviser) {
        this.adminType = true
      }
    },
    saveMessage () {
      const channel = this.isWhatsappPreview ? 'keybe_wp' : this.isInstagramPreview ? 'instagram' : this.isWebPreview ? 'web' : 'facebookMessenger'
      const messageOnTransfer = this.localInbox[this.index].messageOnTransfer.find(item => item.channel === channel)
      messageOnTransfer.message = this.greetingMessage
      messageOnTransfer.active = this.messageActive

      if (messageOnTransfer?.message?.length > 0) {
        messageOnTransfer.active = true
      }
      this.saveConfig(messageOnTransfer)
    },
    saveConfig: debounce(function (messageOnTransfer): void {
      const index: number = this.localInbox[this.index]?.messageOnTransfer?.findIndex((message: any) => message.channel === messageOnTransfer.channel)
      if (index === -1 || index === undefined) {
        this.localInbox[this.index].messageOnTransfer.push(messageOnTransfer)
      } else {
        this.localInbox[this.index].messageOnTransfer[index] = messageOnTransfer
      }
      this.writeActivityLog(index)
      this.$emit('inbox-updated', { messageOnTransfer: this.localInbox[this.index]?.messageOnTransfer || [] })
      EventBus.$emit('inboxUpdated--spaces', this.localInbox)
    }, 100),
    async writeActivityLog (index): Promise<void> {
      const newValue = this.localInbox[this.index].messageOnTransfer[index].message
      const oldValue = this.inboxList[this.index].messageOnTransfer[index].message
      const data: dataToWriteActivityLog = {
        token: this.token,
        updateInput: {
          appUUID: this.selectedApp?.uuid,
          module: ActivityLogModuleTypeEnum.CHAT,
          submodule: ActivitySubmoduleTypeEnum.SPACES,
          action: ActivityActionTypeEnum.SPACE_UPDATE,
          object: this.inboxList[this.index].name,
          user: {
            id: this.user.uuid,
            name: this.user.name + ' ' + this.user.lastName
          },
          payload: {
            field: CDPFieldsEnums.MESSAGE_ON_TRANSFER,
            newValue: newValue,
            oldValue: oldValue
          }
        }
      }
      await ActivityLogsUseCases.writeActivityLog(this.activityLogsController, data)
    },
    statusMessage () {
      if (!this.messageActive) {
        this.greetingMessage = ''
      }
      this.saveMessage()
    },
    closeModal (): void {
      this.showWarning = false
      this.messageWarning = ''
    }
  }
}
