import AppsController from '@/modules/Apps/infrastructure/controllers/Apps.controller'
import AppsUseCases from '@/modules/Apps/application/AppsUseCases'
import type { dataToSelectApp } from '@/modules/Apps/domain/AppsController'
import store from '@/store'
import { KeybeUserExtradata } from '@/models/KeybeUserExtradata'
import { LoggedUser } from '@/modules/Config/GeneralUserConfiguration/domain/LoggedUser'
import UserUseCases from '@/modules/Config/GeneralUserConfiguration/application/UserUseCases'
import UserController from '@/modules/Config/GeneralUserConfiguration/infrastructure/controller/User.controller'
import { userGetter } from '@/modules/Config/GeneralUserConfiguration/domain/UserController'
import { dataToChangePassword, dataToLogIn, dataToLogInByToken, ILogInController, logInResponse } from '../../domain/LoginController'
import LogInRepository from '../repository/Login.repository'

export default class LogInController implements ILogInController {
  repository: LogInRepository

  constructor () {
    this.repository = new LogInRepository()
  }

  abort (): void {
    this.repository.abortGetSuggestions()
  }

  // eslint-disable-next-line no-undef
  async login (data: dataToLogIn): Promise<logInResponse> {
    const response = await this.repository.login(data)
    if (response.status) {
      await this.saveData(response.data)

      return { data: response?.data?.data, status: response.status }
    }

    return { message: response?.data?.message, status: response.status }
  }

  async saveData (response: logInResponse, app?: string) {
    const user: LoggedUser = response?.data?.user || null
    const token = response?.data?.token || ''
    const deviceToken = response?.data?.tokenInfo?.tokenuid || ''

    if (token) store.commit('UserStore/SET_TOKEN', token, { root: true })

    const get = {
      token: token,
      search: '',
      offset: 0,
      limit: 50
    }

    const userExtradata = new KeybeUserExtradata(response?.data?.user?.userData)
    store.commit('UserStore/SET_KEYBE_USER', user, { root: true })
    await store.dispatch('UserStore/setUserExtradata', response?.data?.user?.userData, { root: true })
    store.commit('UserStore/SET_TOKEN', token, { root: true })
    store.commit('UserStore/CHANGE_KEYBE_AUTH', true, { root: true })
    store.commit('UserStore/SET_FIRST_RENDER', false, { root: true })
    store.commit('UserStore/SET_CHANGE_PASSWORD', response.data.changePass, { root: true })
    await store.dispatch('UserStore/setDeviceToken', deviceToken)
    await store.dispatch('AppStore/setWebinarPopup', true)
    // set language
    const language = userExtradata.getLanguage()
    if (language) {
      store.commit('AppStore/SET_APP_LANGUAGE', language, { root: true })
    }

    const appsController = new AppsController()

    if (app) {
      const dataAppSelect: dataToSelectApp = {
        token: token,
        uuid: app,
        name: ''
      }
      await AppsUseCases.selectApp(appsController, dataAppSelect)
    } else if (user.isSuperAdmin) {
      get.search = 'Keybe AI'
      await AppsUseCases.getApps(appsController, get)
    } else await AppsUseCases.getApps(appsController, get)
  }

  async loginByToken (data: dataToLogInByToken): Promise<boolean> {
    store.commit('UserStore/SET_TOKEN', data.token, { root: true })
    const response = await this.repository.loginByToken(data.token)
    if (response.status) {
      const responseUser: userGetter = await UserUseCases.getUser(new UserController(), {
        token: data.token,
        uuid: response?.data?.data?.user?.uuid
      })
      await this.saveData({
        data: {
          user: responseUser?.data?.user,
          token: data.token
        }
      }, data.app)
      return true
    }
    return false
  }

  async changePassword (data: dataToChangePassword): Promise<boolean> {
    const response = await this.repository.changePassword(data)
    return response.status
  }
}
