<template>
  <svg width="40" height="30" viewBox="0 0 40 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.7115 26.9097C34.5562 27.4228 35.595 27.2226 36.1519 26.3778C38.2983 23.2677 39.5373 19.2003 39.5373 14.9889C39.5373 10.765 38.3108 6.68499 36.1519 3.59371C35.595 2.74893 34.5562 2.54243 33.7115 3.05556C32.7915 3.60623 32.6664 4.73261 33.3109 5.75258C35.013 8.30571 35.9829 11.5722 35.9829 14.9889C35.9829 18.3993 34.9942 21.6595 33.3109 24.2252C32.6789 25.2326 32.7915 26.3653 33.7115 26.9097Z" fill="black" fill-opacity="0.85"/>
    <path d="M26.7778 22.4728C27.5913 22.986 28.6175 22.792 29.1557 22.0285C30.5199 20.1763 31.3396 17.6544 31.3396 14.9887C31.3396 12.3167 30.5199 9.80111 29.1557 7.93008C28.6175 7.17913 27.5913 6.98516 26.7778 7.50455C25.8892 8.07403 25.6952 9.14408 26.4211 10.3017C27.2972 11.5908 27.7728 13.2303 27.7728 14.9887C27.7728 16.7346 27.2846 18.3678 26.4211 19.6694C25.7015 20.8333 25.8892 21.8971 26.7778 22.4728Z" fill="black" fill-opacity="0.85"/>
    <path d="M18.6492 29.6205C19.9883 29.6205 20.9771 28.6192 20.9771 27.2926V2.76267C20.9771 1.44231 19.9883 0.384766 18.6367 0.384766C17.7606 0.384766 17.1161 0.741452 16.1899 1.59875L9.53806 7.83137C9.43794 7.91898 9.32532 7.96276 9.18137 7.96276H4.61955C2.11649 7.96276 0.727295 9.39575 0.727295 12.024V18.0188C0.727295 20.6408 2.11649 22.0738 4.61955 22.0738H9.17512C9.31906 22.0738 9.43169 22.1113 9.5318 22.2052L16.1899 28.4628C17.041 29.2638 17.7481 29.6205 18.6492 29.6205Z" fill="black" fill-opacity="0.85"/>
  </svg>
</template>

<script>
export default {
  name: 'CallSpeakerIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#b5bec7'
    }
  }

}
</script>

<style>

</style>
