<template>
  <svg width="16" height="16" viewBox="0 0 16 16" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.35841 11.547C6.86171 14.0659 9.85672 15.9936 12.2706 15.9936C13.4041 15.9936 14.3908 15.5374 15.0389 14.808C15.6552 14.1076 15.9106 13.5679 15.9106 13.1052C15.9106 12.7325 15.6839 12.4048 15.1283 12.0096L13.0465 10.5125C12.5356 10.143 12.2994 10.0755 11.9928 10.0755C11.7278 10.0755 11.5075 10.1237 11.0605 10.3679L9.71942 11.1132C9.55657 11.2 9.47996 11.2193 9.3618 11.2193C9.20217 11.2193 9.09678 11.1775 8.93396 11.11C8.28257 10.8048 7.37577 10.0948 6.56796 9.28193C5.76652 8.46907 5.14707 7.65945 4.81182 6.99117C4.7735 6.90121 4.71922 6.77269 4.71922 6.63453C4.71922 6.52531 4.7735 6.41606 4.84056 6.2972L5.62603 4.93494C5.83997 4.55904 5.90701 4.35662 5.90701 4.06105C5.90701 3.72692 5.79845 3.37029 5.47597 2.91727L4.0455 0.909237C3.64318 0.334136 3.29516 0 2.83216 0C2.26382 0 1.57733 0.443374 1.08561 0.918876C0.373578 1.60964 0 2.56707 0 3.64338C0 6.08514 1.86789 9.04418 4.35841 11.547Z" :fill="color"/>
  </svg>
</template>

<script>
export default {
  name: 'CallIcon',
  props: {
    width: {
      type: Number,
      default: 16
    },
    height: {
      type: Number,
      default: 18
    },
    color: {
      type: String,
      default: '#b5bec7'
    }
  }

}
</script>

<style>

</style>
