import KeybeRepository from '@/shared/infrastructure/repositories/KeybeRepository'
import { GenericResponse } from '@/models/apis/GenericResponse'
import { HeadersBuilder } from '@/shared/infrastructure/http/HeadersBuilder'
import { nonGetOptions } from '@/shared/infrastructure/http/HttpClient'
import { dataToLogIn } from '../../domain/LoginController'
export default class LogInRepository extends KeybeRepository {
  getSuggestionsAbortController: AbortController

  constructor () {
    super()

    this.getSuggestionsAbortController = new AbortController()
  }

  abortGetSuggestions () {
    this.getSuggestionsAbortController.abort()
    this.getSuggestionsAbortController = new AbortController()
  }

  async login (data: dataToLogIn): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/email`
    const headers = {
      'content-type': 'application/json',
      Accept: 'application/json'
    }
    const requestOptions: nonGetOptions = {
      headers,
      data,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async loginByToken (token: string): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/validate`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader({ token: token })
    const requestOptions: nonGetOptions = {
      headers,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }

  async changePassword (data: any): Promise<GenericResponse> {
    const url = `${process.env.VUE_APP_AUTH_SERVICE}auth/change-password`
    const headers = HeadersBuilder.buildSimpleAutorizationHeader(data)
    const passwordData = {
      currentPassword: data.password,
      newPassword: data.newPassword
    }
    const requestOptions: nonGetOptions = {
      headers,
      data: passwordData,
      url,
      abortController: this.getSuggestionsAbortController
    }

    return await this.client.post(requestOptions)
  }
}
